import { defineStore } from 'pinia';
import {
  listSchools,
  loadSchool,
  listSchoolUsers,
  listSchoolGroups,
  syncSchool as syncSchoolService,
  syncGroup as syncGroupService,
} from './school-service';
import { ref, computed } from 'vue';
import { useSystemStore } from '/@/app/store/system';
import swal from 'sweetalert';

const is = role => user => user.role.includes(role);

export const useSchoolStore = defineStore('schools', () => {
  const data = ref<object>({});
  const users = ref<object[]>([]);
  const groups = ref<object[]>([]);

  const students = computed(() => users.value.filter(is('student')));
  const teachers = computed(() => users.value.filter(is('teacher')));

  const schools = ref<object[]>([]);

  const init = async () => {
    const systemStore = useSystemStore();
    systemStore.isLoading = true;
    const { asPromised, onSuccess } = listSchools();

    onSuccess(({ data }: { data: any }) => {
      schools.value = data
        .sort((a, b) => a.manual - b.manual)
        .map(s => {
          s.students = s.summary?.totalStudents;
          s.teachers = s.summary?.totalTeachers;
          s.groups = s.summary?.totalGroups;

          return s;
        });

      systemStore.isLoading = false;
    });

    return asPromised();
  };

  const fetch = async id => {
    const systemStore = useSystemStore();
    systemStore.isLoading = true;
    users.value = [];
    groups.value = [];
    const { asPromised: req1, onSuccess } = loadSchool(id);
    const { asPromised: req2, onSuccess: onUsersLoad } = listSchoolUsers(id);
    const { asPromised: req3, onSuccess: onGroupsLoad } = listSchoolGroups(id);

    onSuccess(({ data: info }: { data: any }) => {
      data.value = info;
    });

    onUsersLoad(({ data }: { data: any }) => {
      users.value = data;
    });

    onGroupsLoad(({ data }: { data: any }) => {
      groups.value = data;
    });

    await Promise.all([req1(), req2(), req3()]);
    systemStore.isLoading = false;
  };

  const syncSchool = item => {
    let school = schools.value.find(school => school.id === item.id);
    school.syncing = true;
    const { onSuccess, onError } = syncSchoolService(item.id);

    onSuccess(() => {
      // showLoading.value = false
      school.syncing = false;
      swal({
        title: 'Synced',
        text: 'All data is good',
        icon: 'success',
      });
      init().then(() => {});
    });

    onError(error => {
      school.syncing = false;
      swal({
        title: 'Error',
        text: error.message,
        icon: 'error',
      });
    });
  };

  const syncGroup = item => {
    let group = groups.value.find(group => group.id === item.id);
    group.syncing = true;
    const { onSuccess, onError } = syncGroupService(item.id);

    onSuccess(() => {
      group.syncing = false;
      swal({
        title: 'Synced',
        text: 'all good',
        icon: 'success',
      });
    });

    onError(error => {
      group.syncing = false;
      swal({
        title: 'Error',
        text: error.message,
        icon: 'error',
      });
    });
  };

  return {
    init,
    fetch,
    syncSchool,
    syncGroup,
    schools,
    data,
    users,
    students,
    teachers,
    groups,
  };
});
